import $ from 'jquery';
import 'what-input';
import  Cookies from 'js-cookie';
import AOS from 'aos';
import "magnific-popup";
import Swiper from 'swiper';
import Parallax from 'parallax-js'
import 'parsleyjs';
import 'jquery-match-height'
import select2 from 'select2/dist/js/select2.full.js'


// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
window.$ = $;
require('foundation-sites');


// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

let Page = Page || {};
window.Page = Page;

(function($) {
    
    /* ---------------------------------------------
        Global Variables
    --------------------------------------------- */
        
        Page.$window = $(window);
        Page.$html = $('html');
        Page.$body = $('body');
        Page.$document = $(document);
    
    /* ---------------------------------------------
        APP document Ready
    --------------------------------------------- */
    Page.documentOnReady = {
        init: function() {
            Page.initialize.init();
        }
    };
    
    /* ---------------------------------------------
        Page document Load
    --------------------------------------------- */
    Page.documentOnLoad = {
        init: function() {
            Page.initialize.preloader();
        }
    };
    
    /* ---------------------------------------------
        APP document Resize
    --------------------------------------------- */
    Page.documentOnResize = {
        init: function() {
            var t = setTimeout(function() {
                Page.initialize.gridList();
            }, 250);
        }
    };
    
    /* ---------------------------------------------
        Scripts initialization
    --------------------------------------------- */
    Page.$document.ready( Page.documentOnReady.init);
    Page.$window.on('load', Page.documentOnLoad.init);
    Page.$window.on('resize', Page.documentOnResize.init);
    
    /* ------------------------------------------------------------------------------------------------------------------
        #############################################################################################################
    ------------------------------------------------------------------------------------------------------------------ */

    Page.initialize = {
        init: function() {
            Page.initialize.foundation.init();
            Page.initialize.aos();
            Page.initialize.header();
            Page.initialize.formValidation();
            Page.initialize.swiper();
            Page.initialize.sidebar();
            Page.initialize.select2();
            Page.initialize.customFile();
            Page.initialize.magnificPopup();
            Page.initialize.cookie();
            Page.initialize.gridList();
            Page.initialize.innerLinks();
            Page.initialize.compediumPage();
            Page.initialize.product();
            Page.initialize.expandBanner();
        },

        /* ---------------------------------------------
            Foundation
        --------------------------------------------- */
        foundation: {
            init: () => {
                Page.$document.foundation();
            },
            destroy: () => { }
        },
        
        /* ---------------------------------------------
            Preloader
        --------------------------------------------- */
        preloader: function() {
            Page.$body.removeClass("loading");
            Page.$body.addClass("loaded");
        },

        /* ---------------------------------------------
            Expand banner
        --------------------------------------------- */
        expandBanner: function(){
            var banner_show = theme.expandBanner.show || false;

            if ( !banner_show ){
                return;
            }
            if ( $('#modal-banner').length > 0 ){
                var banner_delay = theme.expandBanner.delay || 4000;
                var banner_session = sessionStorage.getItem('expand-banner') || false;
                var $bannerExpand = $("#modal-banner");
            }

            if ( !banner_session ) {
                setTimeout(function(){
                    $bannerExpand.foundation('open');
                }, banner_delay);
            }
            
            $('#modal-banner .close-banner').click(function(){
                sessionStorage.setItem('expand-banner', 'show');
            });
        },

        /* ---------------------------------------------
            Product
        --------------------------------------------- */
        product: function() {
            if ( $('.product-equalizer').length > 0 ){
                $('.product-equalizer .product-image').matchHeight({
                    byRow: false,
                    property: 'height',
                    target: null,
                    remove: false
                });

                $('.product-equalizer .product-title').matchHeight({
                    byRow: false,
                    property: 'height',
                    target: null,
                    remove: false
                });
            }
        },

        /* ---------------------------------------------
            Cookie
        --------------------------------------------- */
        cookie: function () {
            let $cookieDiv = $("#cookie");
            let cookie_delay = 3000;
            if ( !Cookies.get('cookie-notyfication') ) {
                setTimeout(function () {
                    $cookieDiv.addClass("show");
                }, cookie_delay);
                $('#cookie-close').on('click', function () {
                    Cookies.set('cookie-notyfication', 'accept', { expires: 365 });
                    $cookieDiv.removeClass("show");
                });
            } 
        },

        /* ---------------------------------------------
            Preloader
        --------------------------------------------- */
        customFile: function() {
            $('.custom-file').each(function(){
                let custoFile = $(this);
                let $inputElement = $(this).find('input[type="file"]');
                let $inputElementText = $(this).find('.input-file-label');
                let $inputTextDefault = $inputElementText.innerHTML;
                let droppedFile;


                $(this).on('drag dragstart dragend dragover dragenter dragleave drop', function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                });

                $(this).on('drop', function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                    droppedFile = e.originalEvent.dataTransfer.files;
                    $inputElement.trigger('change');
                });

                $inputElement.on("change", function() {
                    const fileList = droppedFile || this.files; 
                    if (fileList.length > 1){
                        $inputElementText.text('');
                        for (let file = 0; file < fileList.length; file++) {
                            $inputElementText.prepend( '<p>' + fileList[file].name +'</p>' );
                        }
                    } else {
                        if ( fileList.length ){
                            $inputElementText.text( fileList[0].name)
                        } else {
                            $inputElementText.text($inputTextDefault);
                        }
                    }
                });
            });
        },
        
        /* ---------------------------------------------
            Aos
        --------------------------------------------- */
        aos: function() {
            document.addEventListener('aos:in', function( aosElem ) {
                // aos in
            });
              
            document.addEventListener('aos:out', function( aosElem ) {
                // aos out
            });
            AOS.init({
                // Global settings
                disable: $(window).width() < 1199, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
                startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
                initClassName: 'aos-init', // class applied after initialization
                animatedClassName: 'aos-animate', // class applied on animation
                useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
              
                // Settings that can be overriden on per-element basis, by `data-aos-*` attributes:
                offset: 250, // offset (in px) from the original trigger point
                delay: 500, // values from 0 to 3000, with step 50ms
                duration: 400, // values from 0 to 3000, with step 50ms
                easing: 'ease', // default easing for AOS animations
                once: true, // whether animation should happen only once - while scrolling down
                mirror: false, // whether elements should animate out while scrolling past them
                anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
            });
        },

        /* ---------------------------------------------
            boostrapSelect
        --------------------------------------------- */
        select2: function() {
            $('select:not(.phpdebugbar-datasets-switcher)').select2({
                minimumResultsForSearch: -1, 
                width: '100%',
            }, true);
            
            // select filter
            $('select[data-filter-for]').on('change', function() {
                var filterFor = $(this).attr('data-filter-for');
                var val = $(this).val();
                $('#' + filterFor).find('div[data-filter]').removeClass('active');
                $('#' + filterFor).find('div[data-filter="' + val + '"]').addClass('active');
                $(this).blur();
            });
            
            //select load content
            $('select[data-load]').each(function(){
                var loadIn = $(this).attr('data-load-in');
                var load = $(this).attr('data-load');
                $("#" + loadIn).load( load );
            });
            
            $('select[data-load]').on('change', function() {
                var loadIn = $(this).attr('data-load-in');
                var val = $(this).val();
                $("#" + loadIn).load( val );
            });
        },

        /* ---------------------------------------------
            gridList
        --------------------------------------------- */
        gridList: function() {
            if ( $('.list-grid').length > 0 ){
                if ( Page.$window.width() > 1200 ){
                    $('.list-grid > li').each(function(idx, item){
                        var transform = ( $(item).find('.content-inner').outerHeight() ) - ($(item).find('.content-title').outerHeight() + parseInt($(item).find('.content-inner').css('padding-top')) );
                        $(item).find('.content-inner').css({
                            "-webkit-transform":"translateY(" + transform + "px)",
                            "-ms-transform":"translateY(" + transform + "px)",
                            "transform":"translateY(" + transform + "px)",
                        })
                    });
                }

                
                let listEq = new Foundation.Equalizer($('.list-grid .content-inner'), {
                    equalizeByRow: true
                });
            }
        },

        /* ---------------------------------------------
            Inner links
        --------------------------------------------- */
        compediumPage: function(){
            if($('#filter-letters').length){
                let $lettersContainer = $('#filter-letters');
                let $filterAccordion = $('#compendium-accordion');
                let $filterAlert = $('#filter-alert');
                let alphabet = "abcdefghijklmnoprstuwxyz".split('');
                $.each(alphabet, function( index, value ) {
                    $('<span class="badge badge-letter" data-filter="'+  value +'">' + value +'</span>').appendTo($lettersContainer);
                });

                let $badges = $lettersContainer.find('.badge');

                $('#filter-letters .badge').on('click', function(){
                    let letter = $(this).attr('data-filter');
                    $badges.removeClass('active');
                    $(this).addClass('active');
                    if (letter !== 'all'){
                        letter = letter.toUpperCase();
                        let titles = $filterAccordion.find('.accordion-title');
                        let match = 0;
                        $.each( titles, function( index, item ){
                            let firstLetter = $(item).text().charAt(0).toUpperCase();
                            if (letter !== firstLetter){
                                $(item).parent().addClass('hide');
                            } else {
                                $(item).parent().removeClass('hide');
                                match++;
                            }
                        });

                        if ( !match ){
                            $filterAlert.css('display', 'block');
                        } else {
                            $filterAlert.css('display', 'none');
                        }
                       
                    } else {
                        $filterAlert.css('display', 'none');
                        $filterAccordion.find('.accordion-item').removeClass('hide')
                    }
                })
            }
        },

        /* ---------------------------------------------
            Inner links
        --------------------------------------------- */
        innerLinks: function(){
            if($('.inner-link').length){
                $('.inner-link').on('click', function (e) {
                    e.preventDefault();
                    var href = $(this).attr('href');
                    var speed = parseInt( $(this).attr('data-speed'), 10) || 900;
                    var offset = $(this).attr('data-offset') || $('.header').outerHeight() + 30;
                    $('html, body').animate({
                        scrollTop: $(href).offset().top - offset
                    }, speed);
                    return false;
                });
            }
        },

        /* ---------------------------------------------
            Header, main menu
        --------------------------------------------- */
        header: function () {
            let $offcanvasMenu= $('#offcanvas');
            let $toggleMenu = $("#toggle-menu");
            let $header = $('.header');
            let scroll_amount = 50;
            
            //menu
            $toggleMenu.click(function(){
                $(this).toggleClass("is-active");
                $offcanvasMenu.toggleClass('active');
                Page.$body.toggleClass("menu-is-active");
            });

            // scroll event
            if ( Page.$window.scrollTop() > scroll_amount ){
                Page.$body.addClass("body-scroll");
            }
            Page.$window.scroll(function() {
                if ( $(this).scrollTop() > scroll_amount ){
                    Page.$body.addClass("page-scroll");
                } else{
                    Page.$body.removeClass("page-scroll");
                }
            });
        },

        /* ---------------------------------------------
            Header
        --------------------------------------------- */
        sidebar: function() {
            let $sidebar= $('#sidebar');
            let $sidebaTrigger = $('#sidebar-trigger');
            let $sidebarClose = $('#sidebar-close');
            
            $sidebaTrigger.on('click', function(){
                $sidebar.addClass('open');
                Page.$body.addClass('sidebar-is-open');
            });
            $sidebarClose.on('click', function(){
                $sidebar.removeClass('open');
                Page.$body.removeClass('sidebar-is-open');
            });
        },
        
        /* ---------------------------------------------
            Form validation
        --------------------------------------------- */
        formValidation: function () {

            $('#newsletter-trigger').on('click', function(){
                var emailVal = $('#newsletter-emial').val();
                $('#newsletter-emial-modal').val(emailVal);
            })
            
            //Global validation
            $("form[novalidate] select:required").change(function() {
                $(this).parsley().validate();
            }); 
            
            window.Parsley.on('field:error', function() {
                this.$element.addClass('form-control-danger');
                if(this.$element.is('[type="file"]')) {
                   this.$element.closest('.custom-file').addClass('form-control-danger');
                }
            });
                
            window.Parsley.on('field:success', function() {
                this.$element.removeClass('form-control-danger');
                this.$element.addClass('form-control-success');
                if(this.$element.is('[type="file"]')) {
                    this.$element.closest('.custom-file').removeClass('form-control-danger');
                 }
            });

            // Form gruop heleper classes
            $('form[novalidate]').parsley({
                successClass: "has-success",
                errorClass: "has-danger",
                classHandler: function(el) {
                    return el.$element.closest(".form-group");
                },
                errorsWrapper: '<ul class="parslay-danger"></ul>',
                errorElem: '<li></li>'
            });

            $('form[novalidate]').on('submit', function( evt){
                var form = $(this);
                form.parsley().validate();
                if (form.parsley().isValid()){
                    if ( form.attr('data-prevent') === 'true' ){
                        evt.preventDefault();
                    }
                    if ( form.attr('data-modal-show')){
                        var $modal = $(form.attr('data-modal-show'));
                        form[0].reset();
                        $modal.foundation('open');
                    }
                }
            });


            // bind parsley to the form. Exclude all disabled fields
            $('#form-checkout').parsley({
                excluded: 'input[type=button], input[type=submit], input[type=reset], input[type=hidden], :disabled'
            });
            
            let $differentAddress = $('#differentAddress');
            $differentAddress.on('change', function() {
                if ( $(this).prop('checked') ) {
                    $('.different-address-container').find('input,select,radio').prop('disabled', false);
                } else {
                    $('.different-address-container').find('input,select,radio').prop('disabled', true);
                    $('.different-address-container').find('input,select,radio, .form-group').removeClass('has-danger form-control-danger');
                    $('#form-checkout').parsley().reset();
                }
            });
        },
        
        /* ---------------------------------------------
            Swiper
        --------------------------------------------- */
        swiper: function(){
            // home swiper
            if ( $('#home-swiper').length > 0 ) {
                let $homeSwiper = $('#home-swiper');
                let homeSwiperLength = $homeSwiper.find('.swiper-slide').length;

                if ( homeSwiperLength < 2 ){
                    $('.home-swiper-nav').css('display', 'none');
                }
                let swiperHome = new Swiper($homeSwiper, {
                    loop: homeSwiperLength > 1,
                    autoplay: {
                        delay: 7000,
                        disableOnInteraction: false
                    },
                    effect: 'fade',
                    fadeEffect: {
                        crossFade: true
                    },
                    navigation: {
                        nextEl: '#home-swiper-next',
                        prevEl: '#home-swiper-prev',
                    },
                    pagination: {
                        el: '#home-swiper-pagination',
                        clickable: true,
                        type: 'bullets',
                        renderBullet: function (index, className) {
                            return '<span class="' + className + '">' + '0' + (index + 1) + '</span>';
                        },
                    },
                    speed: 3000,
                    simulateTouch: false,
                    touchRatio: 0,
                    allowTouchMove: false,
                    on: {
                        slideChange: function(){}
                    },
                });

                var scene = document.getElementById('home-swiper');
                var parallax = new Parallax(scene, {
                    selector: '.swiper-slide-bg',
                });
            }

            // article swiper
            if ( $('#article-swiper').length > 0 ) {
                let $articleSwiper = $('#article-swiper');
                let articleSwiperLength = $articleSwiper.find('.swiper-slide').length;
                let swiperArticle = new Swiper($articleSwiper, {
                    loop: articleSwiperLength > 3,
                    slidesPerView: 3,
                    spaceBetween: 20,
                    observer: true,
                    observeParents: true,
                    simulateTouch: false,
                    touchRatio: 0,
                    allowTouchMove: false,
                    navigation: {
                        nextEl: $articleSwiper.find('.swiper-button-next'),
                        prevEl: $articleSwiper.find('.swiper-button-prev'),
                    },
                    breakpoints: {
                        1200: {
                            slidesPerView: 2,
                            spaceBetween: 15,
                        },
                        767: {
                            slidesPerView: 1,
                        },
                    }
                });
            }

            // article swiper v2
            if ( $('#article-swiper-v2').length > 0 ) {
                let $articleSwiperV2 = $('#article-swiper-v2');
                let articleSwiperV2Length = $articleSwiperV2.find('.swiper-slide').length;
                let swiperArticleV2 = new Swiper($articleSwiperV2, {
                    loop: articleSwiperV2Length > 3,
                    slidesPerView: 4,
                    spaceBetween: 20,
                    observer: true,
                    observeParents: true,
                    simulateTouch: false,
                    touchRatio: 0,
                    allowTouchMove: false,
                    navigation: {
                        nextEl: $articleSwiperV2.find('.swiper-button-next'),
                        prevEl: $articleSwiperV2.find('.swiper-button-prev'),
                    },
                    breakpoints: {
                        1200: {
                            slidesPerView: 2,
                            spaceBetween: 15,
                        },
                        768: {
                            slidesPerView: 1,
                        },
                    }
                });
            }

            // article swiper v2
            if ( $('#product-gallery').length > 0 ) {
                let $productGallery = $('#product-gallery');
                let productGalleryLength = $productGallery.find('.swiper-slide').length;
                let swiperproductGallery = new Swiper($productGallery, {
                    loop: productGalleryLength > 1,
                    slidesPerView: 1,
                    spaceBetween: 0,
                    observer: true,
                    observeParents: true,
                    simulateTouch: false,
                    touchRatio: 0,
                    allowTouchMove: false,
                    navigation: {
                        nextEl: $productGallery.find('.swiper-button-next'),
                        prevEl: $productGallery.find('.swiper-button-prev'),
                    },
                });
            }
        },

        /* ---------------------------------------------
            magnificPopup
        --------------------------------------------- */
        magnificPopup: function() {
            let $mfpGallery = $('.mfp-gallery');
            if ($mfpGallery.length) {
                $mfpGallery.each(function() { // the containers for all your galleries
                    $(this).magnificPopup({
                        delegate: 'a:not(.disable-mfp)', // the selector for gallery item
                        type: 'image',
                        //mainClass: 'mfp-with-zoom', // this class is for CSS animation below
                        mainClass: 'mfp-zoom-in',
                        showCloseBtn: true,
                        closeBtnInside: true,
                        closeOnContentClick: true,
                        closeMarkup: '<span class="mfp-close"><span class="icon-cross-out"></span></span>',
                        gallery: {
                            enabled: true,
                            arrowMarkup: '<span title="%title%" class="mfp-arrow mfp-arrow-%dir%"></span>', // markup of an arrow button
                        },
                        image: {
                            // titleSrc: function(item) {
                            //     return item.el.find('.overlay-content').html();
                            // }
                        },
                        removalDelay: 300, //delay removal by X to allow out-animation
                        callbacks: {
                            open: function() {
                                //overwrite default prev + next function. Add timeout for css3 crossfade animation
                                $.magnificPopup.instance.next = function() {
                                    var self = this;
                                    self.wrap.removeClass('mfp-image-loaded');
                                    setTimeout(function() { $.magnificPopup.proto.next.call(self); }, 120);
                                }
                                $.magnificPopup.instance.prev = function() {
                                    var self = this;
                                    self.wrap.removeClass('mfp-image-loaded');
                                    setTimeout(function() { $.magnificPopup.proto.prev.call(self); }, 120);
                                }
                            },
                            imageLoadComplete: function() {
                                var self = this;
                                setTimeout(function() { self.wrap.addClass('mfp-image-loaded'); }, 16);
                            }
                        }
                    });
                });
            };
            if ( $('.popup-video').length > 0 ){
                $('.popup-video').magnificPopup({
                    disableOn: 700,
                    type: 'iframe',
                    mainClass: 'mfp-fade',
                    removalDelay: 160,
                    preloader: false,
                    fixedContentPos: false
                });
            }

            if ( $('[data-lightbox="inline"]').length > 0 ){
                $('[data-lightbox="inline"]').magnificPopup({
                    type:'inline',
                });
            }
        },
    };
})(jQuery);
